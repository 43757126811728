import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck, faRotate } from "@fortawesome/free-solid-svg-icons";

export default function StatusPill({ status, text, ...props }) {
  const basicStyle =
    props.className +
    " bg-gradient-to-t flex from-90% shadow-inner font-bold tracking-tight rounded-3xl pr-4 py-2 text-sm cursor-pointer";
  const SuccessStyle = "from-lime-500 to-lime-600 " + basicStyle;
  const ErrorStyle = "from-red-500 to-red-600 " + basicStyle;
  const NeutralStyle = "from-gray-400/50 to-gray-400 " + basicStyle;
  const customIcon = props.icon ?? null;
  let propperStyle = "";
  let textColor = "";

  switch (status) {
    case true:
      propperStyle = SuccessStyle;
      textColor = "text-lime-700";
      break;
    case false:
      propperStyle = ErrorStyle;
      textColor = "text-red-700";
      break;
    default:
      propperStyle = NeutralStyle;
      textColor = "text-gray-500";
      break;
  }

  return (
    <div {...props} className={propperStyle}>
      <div className=" w-5 h-5 mx-2 shadow-inner bg-white opacity-40 rounded-full">
        {status === null && (
          customIcon || <FontAwesomeIcon
            className="animate-spin duration-1000 text-gray-400"
            icon={faRotate}
          />
        )}
        {status === true && (
          customIcon || <FontAwesomeIcon className="text-lime-700" icon={faCheck} />
        )}
        {status === false && (
          customIcon || <FontAwesomeIcon className="text-red-700" icon={faXmark} />
        )}
      </div>
      <span className={textColor}>{text}</span>
    </div>
  );
}
