import React, { useEffect, useState } from "react";
import BaseLayout from "../../Layouts/BaseLayout";
import BackOfficeService from "../../Services/BackOfficeService";
import toast from "react-hot-toast";

const Settings = () => {
  const [formData, setFormData] = useState({
    host: "-",
    time_door_1: "-",
    time_door_2: "-",
  });

  const liStyle =
    "hover:bg-gray-400 cursor-pointer px-2 py-2 flow-root flex text-sm";

  const getData = () => {
    BackOfficeService.getSettings()
      .then((response) => {
        setFormData({
          ...formData,
          host: response.data.host,
          time_door_1: response.data.time_door_1,
          time_door_2: response.data.time_door_2,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.promise(BackOfficeService.saveSettings(formData), {
      loading: "Guardando...",
      success: (response) => {
        return "Guardado";
      },
      error: (error) => {
        return "Error al guardar";
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <BaseLayout>
      <section className="pt-20 px-2">
        <h1 className="text-2xl font-bold mb-2 tracking-tight">AJUSTES</h1>
        <form onSubmit={handleSubmit}>
          <ul className="bg-gray-300 rounded-lg mx-2">
            <li className={liStyle + " rounded-t-lg border-gray-200 border-b"}>
              <span className="flex float-left text-gray-500">
                <img
                  className="h-5 mr-1"
                  src="/assets/img/server.svg"
                  alt="host ip"
                />
                Dirección IP
              </span>
              <span className="float-right flex">
                <input
                  className="!text-right bg-transparent outline-0 focus:outline-none"
                  type="text"
                  id="host"
                  name="host"
                  value={formData.host}
                  onChange={handleChange}
                />
              </span>
            </li>
            <li className={liStyle + " border-gray-200 border-b"}>
              <span className="flex float-left text-gray-500">
                <img
                  className="h-5 mr-1"
                  src="/assets/img/clock.svg"
                  alt="host ip"
                />
                Tiempo de puerta &nbsp;
                <font className="font-bold">principal</font>
              </span>
              <span className="float-right flex">
                <input
                  className="!text-right bg-transparent w-10 outline-0 focus:outline-none"
                  type="text"
                  id="time_door_1"
                  name="time_door_1"
                  value={formData.time_door_1}
                  onChange={handleChange}
                />
                &nbsp;segundos
              </span>
            </li>
            <li className={liStyle + " rounded-b-lg"}>
              <span className="flex float-left text-gray-500">
                <img
                  className="h-5 mr-1"
                  src="/assets/img/clock.svg"
                  alt="host ip"
                />
                Tiempo de puerta &nbsp;
                <font className="font-bold">interior</font>
              </span>
              <span className="float-right flex">
                <input
                  className="!text-right bg-transparent w-10 outline-0 focus:outline-none"
                  type="text"
                  id="time_door_2"
                  name="time_door_2"
                  value={formData.time_door_2}
                  onChange={handleChange}
                />{" "}
                &nbsp;segundos
              </span>
            </li>
          </ul>
          <center className="mt-10">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded-2xl"
            >
              Guardar
            </button>
          </center>
        </form>
      </section>
    </BaseLayout>
  );
};

export default Settings;
