import React, { useEffect, useState } from "react";
import BaseLayout from "../../Layouts/BaseLayout";
import BackOfficeService from "../../Services/BackOfficeService";
import toast from "react-hot-toast";

const History = () => {

  useEffect(() => {
  }, []);

  return (
    <BaseLayout>
      <section className="pt-20 px-2">
        <h1 className="text-2xl font-bold mb-2 tracking-tight">Histórico de accesos</h1>
        
      </section>
    </BaseLayout>
  );
};

export default History;
