import React, { useEffect, useState } from "react";
import BaseLayout from "../../Layouts/BaseLayout";
import BackOfficeService from "../../Services/BackOfficeService";
import StatusPill from "./Components/StatusPill";
import toast from "react-hot-toast";
import "./assets/animations.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import DoorButton from "./Components/DoorButton";

const Dashboard = () => {
  const [backOfficeStatus, setbackOfficeStatus] = useState(null);
  const [chipStatus, setChipStatus] = useState(null);
  const [codeStatus, setCodeStatus] = useState(null);
  const [lockStatus, setLockStatus] = useState(null);
  const [doorsTime, setDoorsTime] = useState({
    time_door_1: 20,
    time_door_2: 20
  });

  const cardSceneStyle =
    "bg-gradient-to-t from-gray-400 to-gray-300 rounded-lg p-4 !w-96 min-w-max max-w-md text-sm text-center tracking-tight font-bold cursor-pointer grid justify-center text-white h-32 shadow-lg shadow-gray-400/50 place-items-center";

  const getStatus = () => {
    getBackOfficeStatus();
    getChipStatus();
    getCodeStatus();
  };

  const reTryStatus = (element) => {
    switch (element) {
      case "backOffice":
        getBackOfficeStatus();
        break;
      case "chip":
        getChipStatus();
        break;
      case "code":
        getCodeStatus();
        break;
      default:
        break;
    }
  };

  const getBackOfficeStatus = () => {
    setbackOfficeStatus(null);
    BackOfficeService.getStatus()
      .then((response) => {
        setbackOfficeStatus(true);
      })
      .catch(function (error) {
        if (
          error.response?.status === 401 ||
          error.response?.status === 403 ||
          error.response?.status === 440
        ) {
          window.location.href = "/login";
        }
        setbackOfficeStatus(false);
        console.log(error);
        toast.error("Error");
      });
  };

  const getChipStatus = () => {
    setChipStatus(null);
    BackOfficeService.getChipStatus()
      .then((response) => {
        setChipStatus(true);
      })
      .catch(function (error) {
        setChipStatus(false);
        console.error(error);
        toast.error("Error al conectar servicio");
      });
  };

  const getCodeStatus = () => {
    setCodeStatus(null);
    BackOfficeService.getCodeStatus()
      .then((response) => {
        if (response.data === 1) {
          setLockStatus(
            <FontAwesomeIcon className="text-gray-700" icon={faLock} />
          );
        } else {
          setLockStatus(
            <FontAwesomeIcon className="text-green-600" icon={faLockOpen} />
          );
        }
      })
      .catch(function (error) {
        setCodeStatus(false);
        console.error(error);
        toast.error("Error al conectar servicio");
      });
  };

  useEffect(() => {
    getStatus();
    BackOfficeService.getSettings()
      .then((response) => {
        setDoorsTime({
          ...doorsTime,
          time_door_1 : response.data.time_door_1,
          time_door_2 : response.data.time_door_2
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <BaseLayout>
      <section className="pt-20 pb-5 px-2 h-full flex gap-2 items-center justify-center text-center bg-gray-300">
        <StatusPill
          onClick={() => {
            reTryStatus("backOffice");
          }}
          className="w-1/3"
          text="Servidor"
          status={backOfficeStatus}
        />
        <StatusPill
          onClick={() => {
            reTryStatus("chip");
          }}
          className="w-1/3"
          text="Chip"
          status={chipStatus}
        />
        <StatusPill
          onClick={() => {
            reTryStatus("code");
          }}
          icon={lockStatus}
          className="w-1/3"
          text="Código"
          status={codeStatus}
        />
      </section>
      <hr className="border-gray-400/50" />
      <section className="mt-5 px-2">
        <h1 className="text-2xl font-bold mb-2 tracking-tight">PUERTAS</h1>
        <div className="grid grid-cols-2 px-2 gap-2">
          <DoorButton
            valueOnClick="principal"
            image="/assets/img/fence_door.png"
            text="Principal"
            doorTime={doorsTime.time_door_1}
          />
          <DoorButton
            valueOnClick="in"
            image="/assets/img/in_door.png"
            textClassName="-mt-6"
            imgClassName="h-20"
            text="Interior"
            doorTime={doorsTime.time_door_2}
          />
        </div>
      </section>
      <section className="mt-14 pl-2">
        <h1 className="text-2xl font-bold mb-2 tracking-tight">ACCIONES</h1>
        <div className="overflow-x-auto px-2">
          <div className="flex space-x-2 !h-36 no-scrollbar">
            <div className={cardSceneStyle}>
              <div>
                <img
                  className="h-16"
                  src="/assets/img/entering_icon.png"
                  alt="person entering"
                />
              </div>
              <div>Entrar</div>
            </div>
            <div className={cardSceneStyle}>
              <div>
                <img
                  className="h-16"
                  src="/assets/img/exiting_icon.png"
                  alt="person exiting"
                />
              </div>
              <div>Salir</div>
            </div>
            <div className={cardSceneStyle}>
              <div>
                <img
                  className="h-16"
                  src="/assets/img/inside_icon.png"
                  alt="inside"
                />
              </div>
              <div>Interior</div>
            </div>
            <div className={cardSceneStyle}>
              <div>
                <img
                  className="h-16"
                  src="/assets/img/outside_icon.png"
                  alt="putside"
                />
              </div>
              <div>Exterior</div>
            </div>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default Dashboard;
