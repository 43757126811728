import { Navigate } from "react-router-dom";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const token = localStorage.getItem("token"); //window.isLoggedIn;
  const authUser = localStorage.getItem("user");

  if (
    !token ||
    token === "null" ||
    token === null ||
    !authUser ||
    authUser === null ||
    authUser === "null"
  ) {
    return <Navigate to="/login" />;
  }

  return children;
}
