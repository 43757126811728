import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_PARENT_APP_HOST + '/api';

class BackOfficeServices {
  async getSettings() {
    return await axios.get(API_BASE_URL + "/get/settings");
  }

  async getStatus() {
    return await axios.get(API_BASE_URL + "/status");
  }

  async saveSettings(formData) {
    return await axios.post(API_BASE_URL + "/save-settings", formData);
  }

  async getDoorStatus(doorNumber) {
    return await axios.get(API_BASE_URL + "/get", {
      params: { id: doorNumber },
      headers: {
        // Set the Content-Type to application/json
        "content-type": "multipart/form-data",
        // Set the Authorization header to JWT followed by the actual token
        authorization: "JWT",
        // Set the Accept header to application/json
        accept: "application/json",
        // You may not need this unless you are making cross-origin requests
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  async getChipStatus() {
    return await axios.get(API_BASE_URL + "/get/chip/status");
  }

  async getCodeStatus() {
    return await axios.get(API_BASE_URL + "/get/code/status");
  }

  async openDoor(doorNumber) {
    return await axios.post(API_BASE_URL + "/open/doors", { door: doorNumber });
  }
}

const BackOfficeService = new BackOfficeServices();
export default BackOfficeService;
