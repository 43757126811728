import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../Layouts/base.css";
import { useAuth } from "../../Components/AuthContext";
import { Loader } from "../../Components";

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginCall, setLoginCall] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_PARENT_APP_HOST + "/auth/login";
    setLoginCall(true);
    await axios
      .post(
        url,
        {
          email: formData.email,
          password: formData.password,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log("Logged!");
        login(response.data.access_token);
        setLoginCall(false);
        navigate("/");
      })
      .catch((error) => {
        setLoginCall(false);
        if (error.response?.status === 401 || error.response?.status === 403) {
          setErrorMsg("Usuario o contraseña no válidos");
        } else {
          setErrorMsg("Error desconocido al iniciar sesión");
        }
        console.error("Inicio de sesión fallido", error);
      });
  };

  return (
    <main className="min-h-screen flex justify-center items-center">
      <div className="bg-gray-200 border-gray-700 p-8 rounded-lg shadow-md w-full sm:w-96">
        <div className="text-center mb-8">
          <img
            src={"/assets/img/logo_opensesame.png"}
            alt="Logo de la aplicación"
            className="mx-auto w-20 h-20 drop-shadow-lg"
          />
          <h2 className="text-3xl font-semibold -mt-2 text-white drop-shadow-lg">
            OpenSesame
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <label className="text-red-700">{errorMsg}</label>
          <div className="mb-6">
            <div className="relative h-15 w-full min-w-[200px]">
              <input
                className="peer h-full w-full rounded-[7px]  !border-2 border-gray-300  bg-transparent px-3 py-2.5 font-sans font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-blue-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                placeholder=" "
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !text-[14px] font-normal leading-tight text-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-blue-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-blue-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Correo Electrónico
              </label>
            </div>
          </div>
          <div className="mb-6">
            <div className="relative h-15 w-full min-w-[200px]">
              <input
                className="peer h-full w-full rounded-[7px]  !border-2 border-gray-300 bg-transparent px-3 py-2.5 font-sans font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-blue-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                placeholder=" "
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !text-[14px] font-normal leading-tight text-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-blue-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-blue-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Contraseña
              </label>
            </div>
          </div>
          <button
            type="submit"
            disabled={loginCall && "disabled"}
            className={
              (loginCall && "text-white bg-blue-300") +
              " w-full bg-blue-500 text-white py-4 !h-15 text-xl font-bold rounded-2xl flex items-center justify-center align-middle" +
              (!loginCall && "hover:bg-blue-600")
            }
          >
            {loginCall && <Loader className="!w-5 !h-5 mr-2 static" />}
            Iniciar Sesión
          </button>
        </form>
      </div>
    </main>
  );
};

export default LoginPage;
