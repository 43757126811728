import React, { Component } from "react";
import BackOfficeService from "../../../Services/BackOfficeService";
import toast from "react-hot-toast";

class DoorButton extends Component {
  constructor(props) {
    super(props);

    this.closedStyle =
      "bg-gradient-to-t from-red-700 to-red-500 shadow-red-700/50";
    this.openStyle =
      "bg-gradient-to-t from-green-700 to-green-500 shadow-green-700/50";

    this.neutralStyle =
      "bg-gradient-to-t from-gray-700 to-gray-500 shadow-gray-700/50";

    this.loadingStyle =
      "bg-gradient-to-tl from-orange-600 to-yellow-600 shadow-orange-600/50";

    this.cardStyle =
      "rounded-lg tracking-tight ease-in-out transition-all duration-300 cursor-pointer font-bold grid justify-center text-white h-32 shadow-xl place-items-center";

    this.state = {
      color: "blue",
      countdown: props.doorTime ?? 20,
      countingDown: true,
      countingTime: false,
      doorColor: this.neutralStyle,
      doorStatus: true,
    };

    this.buttonImg = props.image ?? "";
    this.buttonText = props.text ?? "";
    this.imgClassName = props.imgClassName ?? "h-24";
    this.textClassName = props.textClassName ?? "-mt-8";
    this.valueOnClick = props.valueOnClick ?? "";
    this.totalDoorTime = props.doorTime ?? 20;
  }

  handleClick = () => {
    console.log(this.totalDoorTime);
    if (!this.state.countingTime) {
      this.startCountdown();
    } else {
      this.stopCountdown();
    }
  };

  startCountdown() {
    this.setState({ doorColor: this.loadingStyle, countingTime: true });
    let actualCount = this.state.countdown;
    this.countdownInterval = setInterval(() => {
      if (this.state.countingDown) {
        this.setState({
          countdown: this.state.countdown - 1,
        });
        actualCount--;
      } else {
        this.setState({
          countdown: this.state.countdown + 1,
        });
        actualCount++;
      }
      if (actualCount >= this.totalDoorTime || actualCount <= 0) {
        this.stopCountdown();
        this.setState({
          doorColor:
            actualCount >= this.totalDoorTime
              ? this.closedStyle
              : this.openStyle,
        });
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.countdownInterval);
    this.setState({
      doorColor:
        this.state.countdown >= this.totalDoorTime
          ? this.closedStyle
          : this.openStyle,
    });
    const isCountingDown = this.state.countingDown;
    this.setState({ countingTime: false, countingDown: !isCountingDown });
  }

  buttonClick = () => {
    this.setState({ doorColor: this.loadingStyle });
    BackOfficeService.openDoor(this.valueOnClick)
      .then((response) => {
        this.handleClick();
      })
      .catch((error) => {
        this.setState({ doorColor: this.neutralStyle });
        console.error(error);
        toast.error("Error al accionar puerta");
      });
  };

  render() {
    return (
      <div
        className={this.cardStyle + " " + this.state.doorColor}
        onClick={this.buttonClick}
      >
        <div>
          <img
            className={this.imgClassName}
            src={this.buttonImg}
            alt="door button"
          />
        </div>
        <div className={this.textClassName}>{this.buttonText}</div>
      </div>
    );
  }
}

export default DoorButton;
